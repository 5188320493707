@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Proxima Nova, system-ui, sans-serif;
    background: black;
  }
}

.text-greeeen {
  color: rgb(88, 198, 5);
}

.bg-greeeen {
  background-color: rgba(88, 198, 5, 0.1);
}

.text-bright-yellow {
  background-color: rgba(209, 255, 0, 1);
}

.bg-bright-yellow {
  background-color: rgba(209, 255, 0, 0.1);
}

/*
https://cdn.shopify.com/s/files/1/0155/7453/t/53/assets/ProximaNovaCond-Bold.ttf
*/

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0.01;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s;
}

.fade-out {
  animation: fadeOut 0.5s;
}
.slide-right {
  animation: slide-right 0.4s;
  animation-fill-mode: forwards;
}

.bg-custom-light {
  background-color: #f0f2f5;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #000;
  }
}

.blinker {
  animation: blinkerAnimation 1s linear infinite;
}

@keyframes blinkerAnimation {
  50% {
    opacity: 0;
  }
}

.shimmer-line {
  height: 10px;
  margin-top: 20px;
  background: #777;
}

.shimmer-line-br {
  border-radius: 8px;
}
.shimmer-line-full {
  width: 100%;
}
.shimmer-line-80 {
  width: 80%;
}
.shimmer-line-60 {
  width: 60%;
}
.shimmer-line-40 {
  width: 40%;
}
.shimmer-line-20 {
  width: 20%;
}
.shimmer-line-10 {
  width: 10%;
}

.shimmer-circle {
  background: #777;
  border-radius: 50%;
}

.shimmer-circle-lg {
  height: 129px;
  width: 129px;
}

.shimmer-circle-md {
  height: 65px;
  width: 65px;
}

.shimmer-circle-sm {
  height: 33px;
  width: 33px;
}

.shimmer-circle-x-sm {
  height: 17px;
  width: 17px;
}

.shimmer-wrapper {
  width: 100%;
  height: 100%;
  animation: shimmer-full-view 0.5s forwards
    cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@keyframes shimmer-full-view {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@keyframes shimmer-animation {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
/*
.shimmer-animate {
  animation: shimmer-animation 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@media (prefers-color-scheme: dark) {
  .shimmer-animate {
    animation: shimmer-animation 2s infinite linear;
    background: linear-gradient(to right, #0e0e0e 4%, #414141 25%, #0e0e0e 36%);
    background-size: 1000px 100%;
  }
}
*/
.shimmer-animate {
  animation: shimmer-animation 2s infinite linear;
  background: linear-gradient(to right, #0e0e0e 4%, #414141 25%, #0e0e0e 36%);
  background-size: 1000px 100%;
}

/*
* Input remove auto fill color
*/
input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0px 9999px white;
}

@media (prefers-color-scheme: dark) {
  input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0px 9999px #414141;
    color: red;
  }

  input::-webkit-credentials-auto-fill-button {
    background-color: red;
  }
  /*
  input:-webkit-autofill {
    border: 3px solid darkorange;
  }
  */
}

html {
  -webkit-tap-highlight-color: transparent;
  /* -webkit-tap-highlight-color: rgba(70, 70, 70, 0.5);
 */
}

/* Default translation for desktop */
.translate-y-screen {
  transform: translateY(100vh);
}

/* Adjusted translation for mobile */
@media (max-width: 767px) {
  .translate-y-screen {
    transform: translateY(80vh);
  }
}
